jQuery(document).ready(function( $ ) {

  // wrap menu items
  $('ul.sub-menu').wrap('<div class="pc-sub-menu"></div>');

  // open menu
  $('.pc-menu-open').on('click',function(e){
    e.preventDefault();
    $('body').addClass('pc-frozen');
    $('header.pc-nav').addClass('open');
  });

  // close menu
  $('.pc-menu-close').on('click',function(e){
    e.preventDefault();
    $('body').removeClass('pc-frozen');
    $('header.pc-nav').removeClass('open');
  });


  if ($(window).width() < 1031) {

    // mobile menu
    $('nav:not(.pc-footer-nav) li.menu-item-has-children.show').on('click',function(e){
      $(this).removeClass('show');
    });

    $('nav:not(.pc-footer-nav) li.menu-item-has-children:not(.show)').on('click',function(e){
      $('li.menu-item-has-children').not(this).removeClass('show');
      $(this).toggleClass('show');
    });

    $('nav:not(.pc-footer-nav) li.menu-item-has-children > a').on('click',function(e){
      e.preventDefault();
    });

  }

});
